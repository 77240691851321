// ##########################################################################################
// 								GENERAL RULES
// ##########################################################################################

html {
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	font-family: "Inter", sans-serif;
}

body {
	padding: 0 5%;
	background: var(--light-grey);
	font-family: "Inter", sans-serif;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

ul {
	margin: 0;
	padding: 0;
}

ol li,
ul li {
	color: var(--mid-grey);
}

.password {
	border-color: var(--white);
	border-radius: 25px;
	border-style: solid;
	border-width: 2;
	color: var(--purple);
	float: none;
	padding: 15px 20px;
	background: var(--white);
	font-weight: 700;
	width: 100%;
	text-align: center;
}

// ##########################################################################################
// 								MAIN RULES
// ##########################################################################################
.wrap {
	max-width: 380px;
	margin: 50px auto;
	@include MQ(XS) {
		margin: 0;
	}
}

.logo {
	width: 96%;
	margin-bottom: -5px;
	z-index: 2;
	position: relative;
}

// ##########################################################################################
// 								THE FORM
// ##########################################################################################

.gas-form {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.gas-form__title {
		font-size: 135px;
		font-weight: 900;
		line-height: 0.7;
		color: var(--green);
	}
	label {
		position: absolute;
		clip: rect(0px, 0px, 0px, 0px);
	}
	.location-form {
		position: relative;
		flex: 0 0 100%;
		margin: 0 15px 0 0;
		.location-form__zip {
			width: 100%;
			box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
		}
		.location-form__locate {
			position: absolute;
			top: 13px;
			right: 15px;

			background: transparent;
			padding: 0;
			border: none;
			background: none;
			cursor: pointer;
			svg {
				fill: var(--purple);
				width: 25px;
				height: 25px;
				transition: all 0.3s;
			}
			&:hover svg {
				fill: var(--green);
				cursor: pointer;
			}
		}
		.easy-autocomplete {
			position: relative;
			input {
				border-color: var(--white);
				border-radius: 25px;
				border-style: solid;
				border-width: 2;
				color: var(--purple);
				float: none;
				padding: 15px 20px;
				background: var(--white);
				font-weight: 700;
			}
			input#zip.open {
				box-shadow: none;
				border-radius: 25px 25px 0 0;
			}
			a {
				display: block;
			}
		}

		.easy-autocomplete-container {
			left: 0;
			position: absolute;
			top: 40px;
			width: 100%;
			z-index: 2;
			ul {
				background: var(--white);
				display: none;
				margin-top: 0;
				padding-bottom: 10px;
				padding-left: 1px;
				padding-right: 1px;
				position: relative;
				top: -2px;
				border-radius: 0 0 20px 20px;
				box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
				border: 2px solid var(--white);
				border-top: 0;
			}
			ul li {
				background: inherit;
				border-color: #ccc;
				border-image: none;
				border-style: solid;
				border-width: 0 0px;
				display: block;
				font-size: 14px;
				font-weight: normal;
				padding: 5px 20px;
				color: var(--purple);
			}
			ul li:first-child:before {
				content: "";
				height: 2px;
				width: 99%;
				background: var(--purple);
				margin: -5px 0 10px 0;
				display: block;
			}
			ul li:last-child {
				margin-bottom: 5px;
			}
			ul li.selected {
				background: none repeat scroll 0 0 #f2f5fd;
				cursor: pointer;
			}
			ul li.selected div {
				font-weight: normal;
			}
			ul li div {
				display: block;
				font-weight: normal;
				word-break: break-all;
			}
			ul li b {
				font-weight: bold;
			}
			ul .eac-category {
				color: #aaa;
				font-style: italic;
			}
		}
		.eac-description .eac-item span {
			color: var(--green);
			font-style: normal;
			font-size: 0.9em;
		}
	}
	.search-settings {
		display: flex;
		justify-content: space-between;
		flex: 0 0 100%;
		margin: 20px 0;
		.select {
			display: block;
			flex: 0 0 calc(50% - 10px);
			font-size: 16px;
			font-weight: 700;
			color: var(--purple);
			line-height: 1.3;
			padding: 15px 20px;
			border: 2px solid var(--white);
			border-radius: 25px;
			max-height: 52.4px;
			appearance: none;
			background-color: var(--white);
			background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMTAgMTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM6c2VyaWY9Imh0dHA6Ly93d3cuc2VyaWYuY29tLyIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxLjQxNDIxOyI+PHRpdGxlPkFycm93IERvd248L3RpdGxlPjxkZXNjPkFycm93IERvd248L2Rlc2M+PHBhdGggZD0iTTUsNS4zOTFsMy41OTQsLTMuNTk0bDEuNDA2LDEuNDA2bC0zLjU5NCwzLjU5NGwtMS40MDYsMS40MDZsLTUsLTVsMS40MDYsLTEuNDA2bDMuNTk0LDMuNTk0WiIgc3R5bGU9ImZpbGw6IzViNGVlNDsiLz48L3N2Zz4=");
			background-repeat: no-repeat, repeat;
			background-position: right 0.7em top 50%, 0 0;
			background-size: 0.65em auto, 100%;
			box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
			@media (prefers-color-scheme: dark) {
				background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMTAgMTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM6c2VyaWY9Imh0dHA6Ly93d3cuc2VyaWYuY29tLyIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxLjQxNDIxOyI+PHRpdGxlPkFycm93IERvd248L3RpdGxlPjxkZXNjPkFycm93IERvd248L2Rlc2M+PHBhdGggZD0iTTUsNS4zOTFsMy41OTQsLTMuNTk0bDEuNDA2LDEuNDA2bC0zLjU5NCwzLjU5NGwtMS40MDYsMS40MDZsLTUsLTVsMS40MDYsLTEuNDA2bDMuNTk0LDMuNTk0WiIgc3R5bGU9ImZpbGw6IzIxY2NiMDsiLz48L3N2Zz4=");
			}
			&:hover {
				cursor: pointer;
			}
		}
		.select::-ms-expand {
			display: none;
		}
		.select option {
			font-weight: normal;
		}
	}
	.send-button {
		border: 0;
		border-radius: 25px;
		color: var(--white);
		float: none;
		width: 100%;
		padding: 15px 20px;
		border: 2px var(--purple) solid;
		background: var(--purple);
		font-weight: 700;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
		transition: all 0.5s;
		cursor: pointer;
		&:hover {
			color: var(--green);
			// background: var(--white);
		}
	}
}

// NO CONNECTION OR NO DATA
.gas-form-error {
	background: var(--purple);
	display: block;
	border-radius: 20px;
	padding: 25px;
	line-height: 1.3;
	color: var(--white);
	flex: 100%;
	text-align: center;
	&:empty {
		display: none;
	}
}

// ##########################################################################################
// 								THE GASSTATION LISTING
// ##########################################################################################

ul.gasstation-list {
	margin: 55px auto 25px auto;
	padding: 0;
	li {
		list-style: none;
		padding: 15px 20px;
		background: var(--white);
		width: 100%;
		margin: 15px auto 0 auto;
		box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.05);
		position: relative;
		border-radius: 20px;
		@include MQ(XS) {
			padding: 10px 15px;
			border-radius: 10px;
		}
	}
	.gasstation-single__topline {
		display: flex;
		margin: 0 0 3px 0;
	}
	.gasstation-single__brand {
		font-size: 24px;
		font-weight: 600;
		letter-spacing: 1px;
		font-weight: 700;
		color: var(--purple);
		display: inline;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin: 0 5px 0 0;
		@include MQ(XS) {
			font-size: 22px;
		}
	}
	.gasstation-single__status {
		text-align: left;
		line-height: 1;
		font-size: 30px;
		display: inline-block;
		flex: 1 0 15px;
	}
	.gasstation-single__status--open {
		color: var(--green);
	}
	.gasstation-single__status--closed {
		color: var(--red);
	}
	.gasstation-single__price {
		font-size: 24px;
		font-weight: 400;
		text-align: right;
		font-weight: 600;
		color: var(--purple);
		flex: 1 0 90px;
		@include MQ(XS) {
			font-size: 22px;
		}
	}
	.gasstation-single__city {
		font-size: 14px;
	}
	.gasstation-single__street {
		color: var(--grey);
		font-size: 14px;
		text-transform: capitalize;
		display: inline-block;
		&:after {
			content: "->";
			margin: 0 10px;
		}
	}
	.gasstation-single__distance {
		color: var(--grey);
		font-size: 14px;
		display: inline-block;
	}
}
