// PX to REM - Usage: font-size: rem(37px);
@function rem($px, $base: $base-font-size) {
	@return ($px / $base) * 1rem;
}

// MEDIA QUERY MIXIN
// BREAKPOINTS
$size-xxl: 1600px;
$size-xl: 1280px;
$size-lg: 960px;
$size-md: 840px;
$size-sm: 600px;
$size-xs: 480px;

@mixin MQ($canvas) {
	@if $canvas==XXL {
		@media only screen and (max-width: $size-xxl) {
			@content;
		}
	}
	@if $canvas==XL {
		@media only screen and (max-width: $size-xl) {
			@content;
		}
	}
	@if $canvas==LG {
		@media only screen and (max-width: $size-lg) {
			@content;
		}
	}
	@if $canvas==MD {
		@media only screen and (max-width: $size-md) {
			@content;
		}
	} @else if $canvas==SM {
		@media only screen and (max-width: $size-sm) {
			@content;
		}
	} @else if $canvas==XS {
		@media only screen and (max-width: $size-xs) {
			@content;
		}
	}
}

@mixin GR($color1, $color2) {
	background-image: linear-gradient(180deg, $color1 70%, $color2 30%);
}
