// COLORS
:root {
	--white: #fff;
	--black: #333;
	--purple: #5b4ee4;
	--green: #30e8b6;
	--red: #ff6464;
	--grey: #acacac;
	--mid-grey: #505050;
	--light-grey: #f3f3f3;
}

body.dark {
	--white: rgb(63, 40, 58);
	--black: #333;
	--purple: #21ccb0;
	--green: #30e8b6;
	--red: #ff6464;
	--grey: #bbbbbb;
	--mid-grey: rgb(151, 151, 151);
	--light-grey: #311326;
}

@media (prefers-color-scheme: dark) {
	:root {
		--white: rgb(63, 40, 58);
		--black: #333;
		--purple: #21ccb0;
		--green: #30e8b6;
		--red: #ff6464;
		--grey: #bbbbbb;
		--mid-grey: rgb(151, 151, 151);
		--light-grey: #311326;
	}
}
